import { Link } from 'gatsby'
import React, {FC} from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.scss'


const PayoutsCTA: FC = () => {
  return (
    <div className="cta_container">
      <section className="container">
        <div className="row">
          <div className="col-12 cta_payouts_content">
            <div className="cta_payouts_text">
              <h2 className="text-center text-white">
                <FormattedMessage id="payouts.cta.title"/>
              </h2>
            </div>
            <Link to="/form-lead" className="payouts_cta_button mt-2">
              <FormattedMessage id="payouts.cta.button"/>
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PayoutsCTA