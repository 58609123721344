import React from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat';
import lock from './assets/images/lock.png'
import ch from './assets/images/Chil.svg'
import co from './assets/images/Col.svg'
import mx from './assets/images/Mex.svg'
import pe from './assets/images/Peru.svg'
import './styles.scss'

const countries: {name: string, flag: any}[] = [
  {name: "chile", flag: ch},
  {name: "colombia", flag: co},
  {name: "mexico", flag: mx},
  {name: "peru", flag: pe}
]

const VtexdayHero: React.FC = () => {
  return (
    <section className="hero bg-somos-hero">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 py-layout-3 campaign-title_hero">
            <div className="d-md-flex flex-md-column justify-content-md-around ">
              <div className="payouts-hero-content">
                <h1 className="payouts_business">
                  <FormattedMessage id="payouts.hero.business" values={defaultFormatValues}/>
                </h1>
                <i className="text-white payouts-hero_title">
                  <FormattedMessage id="payouts.hero.result" />
                </i>
                <p className="text-white mt-4">
                  <FormattedMessage id="payouts.hero.content"/>
                </p>
              </div>
              <div className="countries-flags-container">
                <p className="text-white">
                  <FormattedMessage id="payouts.hero.countries"/>
                </p>
                <div className="countries-flags">
                  {
                    countries.map((country) => (
                      <img src={country.flag} alt={country.name} />
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 pl-7">
            <img src={lock} className="payouts-hero_img pl-7" />
          </div>
        </div>
      </div>
      <div className="hero-somos-border"></div>
    </section>
  )
}

export default VtexdayHero