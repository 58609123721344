import React, {FC} from 'react'
import { FormattedMessage } from 'react-intl'
import phone from './assets/images/phone.png'
import lworld from './assets/images/lWorld.png'
import lock from './assets/images/lock.png'
import dart from './assets/images/dart.png'
import './styles.scss'

const cards: {title: string, desc: string, img: any}[] = [
  {
    title: "payouts.industries.cards.card1.title",
    desc: "payouts.industries.cards.card1.desc",
    img: phone
  }, {
    title: "payouts.industries.cards.card2.title",
    desc: "payouts.industries.cards.card2.desc",
    img: lworld
  }, {
    title: "payouts.industries.cards.card3.title",
    desc: "payouts.industries.cards.card3.desc",
    img: lock
  }, {
    title: "payouts.industries.cards.card4.title",
    desc: "payouts.industries.cards.card4.desc",
    img: dart
  }
]

const PayoutsIndustries = () => {
  return (
    <div className="payout-industry-container">
      <section className="container p-7">
        <div className="row">
          <div className="col-12 mt-7">
            <h2 className="text-primary text-center">
              <FormattedMessage id="payouts.industries.title"/>
            </h2>
          </div>
          <div className="col-12 py-7 mt-7 payouts-industry-cards-container">
            {
              cards.map((card) => (
                <div className="payouts-industry-card">
                  <div className="industry-card-header">
                    <h3 className="text-primary industry-card-title">
                      <FormattedMessage id={card.title}/>
                    </h3>
                    <div>
                      <img src={card.img} alt={card.title} />
                    </div>
                  </div>
                  <p>
                    <FormattedMessage id={card.desc}/>
                  </p>
                </div>
              ))
            }
          </div>
          <div className="col-12 mt-7 px-7">
            <p className="text-primary text-center px-7">
              <FormattedMessage id="payouts.industries.desc"/>
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PayoutsIndustries