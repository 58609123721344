import React, {FC} from 'react'
import { FormattedMessage } from 'react-intl'
import cash from './assets/images/cash.svg'
import graph from './assets/images/graph.svg'
import headphones from './assets/images/headphones.svg'
import medal from './assets/images/medal.svg'
import shield from './assets/images/shield.svg'
import './styles.scss'

const cards:{title: string, desc: string, img: any}[] = [
  {
    title: "payouts.manage.cards.card1.title",
    desc: "payouts.manage.cards.card1.desc",
    img: medal
  },{
    title: "payouts.manage.cards.card2.title",
    desc: "payouts.manage.cards.card2.desc",
    img: cash
  },{
    title: "payouts.manage.cards.card3.title",
    desc: "payouts.manage.cards.card3.desc",
    img: graph
  },{
    title: "payouts.manage.cards.card4.title",
    desc: "payouts.manage.cards.card4.desc",
    img: shield
  },{
    title: "payouts.manage.cards.card5.title",
    desc: "payouts.manage.cards.card5.desc",
    img: headphones
  }
]

const PayoutsBeneftsCards:FC = () => {
  return (
    <div className="benefits_payouts_cards_container mt-7">
      {
        cards.map((card) => (
          <div className="benefits_payouts_card">
            <div className="card-img-center">
              <img src={card.img} alt={card.title} />
            </div>
            <div className="card-title">
              <h4 className="text-white text-center">
                <FormattedMessage id={card.title}/>
              </h4>
            </div>
            <p className="text-white text-center">
              <FormattedMessage id={card.desc}/>
            </p>
          </div>
        ))
      }
    </div>
  )
}

export default PayoutsBeneftsCards