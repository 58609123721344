import React from 'react'
import { FormattedMessage } from 'react-intl'
import world from './assets/images/worldC.png'
import Form from './Form';

import './styles.scss'

const PayoutsForm: React.FC = () => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <section className="payouts-bg_form">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <p className="text-white payouts-form-desc">
              <FormattedMessage id="payouts.form.content"/>
            </p>
            <div className="payouts-world">
              <img src={world} alt="world" height={915}/>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <p className="text-white text-center"><b><FormattedMessage id="payouts.form.data"/></b></p>
            <Form pathContext={url}/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PayoutsForm