import React from 'react'
import IndexLayout from '../layouts'
import { LocaleTypes } from '../data/locales';
import PayoutsHero from '../components/Payouts/PayoutsHero/PayoutsHero';
import PayoutsForm from '../components/Payouts/PayoutsForm/PayoutsForm';
import PayoutsBenefits from '../components/Payouts/PayoutsBenefits/PayoutsBenefits';
import PayoutsIndustries from '../components/Payouts/PayoutsIndustries/PayoutsIndustries';
import PayoutsCTA from '../components/Payouts/PayoutsCTA/PayoutsCTA';
import Footer from '../components/Footer/Footer';

interface PayoutsProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const payouts: React.FC<PayoutsProps> = ({pathContext, location}) => {
  const content = () => (
    <>
      <PayoutsHero />
      <PayoutsForm />
      <PayoutsBenefits />
      <PayoutsIndustries />
      <PayoutsCTA />
      <Footer theme="primary"/>
    </>
  )
  return (
    <IndexLayout locale={pathContext.localeCode} location={location} render={content} />
  )
}

export default payouts