import React, {FC} from 'react'
import { FormattedMessage } from 'react-intl'
import './styles.scss'
import PayoutsBeneftsCards from './PayoutsBeneftsCards'

const PayoutsBenefits: FC = () => {
  return (
    <section className="payouts-manage">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="manage">
              <h1 className="text-white text-center ">
                <FormattedMessage id="payouts.manage.title"/>
              </h1>
              <h5 className="text-white text-center">
                <FormattedMessage id="payouts.manage.desc"/>
              </h5>
              <PayoutsBeneftsCards />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PayoutsBenefits